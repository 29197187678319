import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.searchFilter || _vm.centerFilter)?_c('div',{staticClass:"search-options"},[_c('div',{staticClass:"box-container"},[(_vm.searchFilter)?_c('div',{staticClass:"box-half"},[_c(VCardTitle,[_c(VTextField,{staticClass:"ml-n6",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1):_vm._e(),(_vm.centerFilter)?_c('div',{staticClass:"box-quarter"},[_c(VSelect,{staticClass:"pt-4 pl-16",attrs:{"items":_vm.centers,"label":"Center","outlined":""},model:{value:(_vm.center),callback:function ($$v) {_vm.center=$$v},expression:"center"}})],1):_vm._e()])]):_vm._e(),_c('div',{staticClass:"results-count"},[_vm._v(" Showing "),_c('strong',[_vm._v(_vm._s(_vm.count))]),_vm._v(" Program Cohort"),(_vm.count != 1)?_c('span',[_vm._v("s")]):_vm._e()]),_c(VCard,[_c(VDataTable,{attrs:{"headers":_vm._headers,"items":_vm.programs,"search":_vm.search,"multi-sort":"","loading":_vm.loading,"loading-text":"Loading... Please wait"},on:{"pagination":_vm.getPageCount},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("/programs/" + (item.id))}},[_vm._v(_vm._s(item.name))])]}},{key:"item.award[0].name",fn:function(ref){
var item = ref.item;
return [_c('td',{class:_vm.getTableCellClasses(item.award)},[(item.award && item.award.length > 0)?_c('a',{attrs:{"href":("/grants/" + (item.award[0].id))}},[_vm._v(_vm._s(item.award[0].name))]):_c('span',[_vm._v("-")])])]}},{key:"item.award[0].center",fn:function(ref){
var item = ref.item;
return [(item.award && item.award.length > 0 && item.award[0].center)?_c(VChip,{staticClass:"white--text font-weight-medium",attrs:{"color":_vm.getAwardCenterColor(item.award[0].center)}},[(item.award[0].center === 'GovEx')?_c('span',{attrs:{"color":"var(--govex)"}},[_vm._v(_vm._s(item.award[0].center))]):(item.award[0].center === 'BCPI')?_c('span',{attrs:{"color":"var(--bcpi)"}},[_vm._v(_vm._s(item.award[0].center))]):(item.award[0].center === 'Partner')?_c('span',[_vm._v(_vm._s(item.award[0].center))]):_vm._e()]):_c('span',[_vm._v("-")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }