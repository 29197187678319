<template>
  <div>
    <div class="search-options" v-if="searchFilter || centerFilter">
      <div class="box-container">
        <div class="box-half" v-if="searchFilter">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              outlined
              class="ml-n6"
            ></v-text-field>
          </v-card-title>
        </div>
        <div class="box-quarter" v-if="centerFilter">
          <v-select
            class="pt-4 pl-16"
            v-model="center"
            :items="centers"
            label="Center"
            outlined
          ></v-select>
        </div>
      </div>
    </div>
    <div class="results-count">
      Showing <strong>{{ count }}</strong> Program Cohort<span v-if="count != 1"
        >s</span
      >
    </div>
    <v-card>
      <v-data-table
        :headers="_headers"
        :items="programs"
        :search="search"
        multi-sort
        :loading="loading"
        loading-text="Loading... Please wait"
        @pagination="getPageCount"
      >
        <template v-slot:item.name="{ item }">
          <a :href="`/programs/${item.id}`">{{ item.name }}</a>
        </template>
        <template v-slot:item.award[0].name="{ item }">
          <td :class="getTableCellClasses(item.award)">
            <a
              v-if="item.award && item.award.length > 0"
              :href="`/grants/${item.award[0].id}`"
              >{{ item.award[0].name }}</a
            >
            <span v-else>-</span>
          </td>
        </template>
        <template v-slot:item.award[0].center="{ item }">
          <v-chip
            v-if="item.award && item.award.length > 0 && item.award[0].center"
            :color="getAwardCenterColor(item.award[0].center)"
            class="white--text font-weight-medium"
          >
            <span
              v-if="item.award[0].center === 'GovEx'"
              color="var(--govex)"
              >{{ item.award[0].center }}</span
            >
            <span
              v-else-if="item.award[0].center === 'BCPI'"
              color="var(--bcpi)"
              >{{ item.award[0].center }}</span
            >
            <span v-else-if="item.award[0].center === 'Partner'">{{
              item.award[0].center
            }}</span>
          </v-chip>
          <span v-else>-</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<style>
.input-box {
  width: 90%;
}
label[for="search"] {
  height: 25px;
}
#search {
  position: relative;
  top: -6px;
}
.v-data-table-header th {
  white-space: nowrap;
}

.v-data-table__mobile-row:nth-child(3) {
  font-weight: bold;
}
</style>
<script>
import pageCountMixin from "../mixins/pageCount";
import StatusChip from "../components/StatusChip";
export default {
  props: {
    endPoint: {
      type: String,
      required: false,
      default: "programs?ordering=name",
    },
    columns: {
      type: Array,
      required: false,
      default: () => ["Name", "Center", "Grant", "Projects", "Practice"],
    },
    searchFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    centerFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mixins: [pageCountMixin],
  components: {},
  title() {
    return `GEPI: Programs`;
  },
  data() {
    return {
      programs: [],
      search: "",
      center: "",
      practice: "",
      status: "",
      centers: ["", "GovEx", "BCPI"],
      statuses: ["", "On Track", "At Risk", "Off Track"],
      loading: true,
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
          cellClass: "table-item table-link",
        },
        {
          text: "Center",
          value: "award[0].center",
          sortable: true,
          filter: (value) => {
            if (!this.center) return true;
            return value === this.center;
          },
          cellClass: "table-item",
        },
        {
          text: "Grant",
          value: "award[0].name",
          sortable: true,
          class: "table-link",
          "v-if": "item.award && item.award.length > 0",
          style: {
            color: "inherit",
            "pointer-events": "auto",
          },
        },
        {
          text: "Projects",
          value: "projects.length",
          sortable: true,
          cellClass: "table-item",
        },
        // {
        //   text: "Practice",
        //   /* value: "practice", */
        //   value: "practice[1]",
        //   sortable: true,
        //   cellClass: "table-item",
        // },
      ],
    };
  },
  created() {
    this.center = this.mapCenterFromQueryString(this.$route.query.center);
    this.getPrograms();
  },

  computed: {
    _headers() {
      return this.headers.filter((item) => this.columns.includes(item.text));
    },
  },
  watch: {
    programs(currentValue) {
      this.$emit("programs", currentValue);
    },
    beforeMount() {
      this.$emit("programs", this.programs);
    },
    center(newCenter) {
      this.$router.push({
        query: {
          center: newCenter,
        },
      });
    },
  },
  methods: {
    getPrograms: async function() {
      let url = this.endPoint;
      while (url) {
        let response = await this.$api.get(url);
        this.programs.push(...response.data.results);
        url = response.data.next;
      }
      this.loading = false;
    },
    formatPracticeChip: function(practice) {
      switch (practice[0]) {
        case "DVP":
          return "chip-academic";
        case "ANA":
          return "chip-analytics";
        case "BPCDA":
          return "chip-bpcda";
        case "CEGMT":
          return "chip-civicengagement";
        case "COM":
          return "chip-communications";
        case "DIG":
          return "chip-digital";
        case "FUT":
          return "chip-futurism";
        case "INNO":
          return "chip-innovation";
        case "LAD":
          return "chip-learningdelivery";
        case "RSCH":
          return "chip-research";
        case "SDLVY":
          return "chip-servicedelivery";
      }
    },
    mostRecentProjectStatus(projects) {
      let status = "On Track";
      let updates =
        projects.length && projects.filter((p) => p.last_update != null);
      if (updates.length > 1) {
        status = projects.reduce((a, b) =>
          a.last_update && a.last_update > b.last_update ? a : b
        )["status"][1];
      } else if (updates.length) {
        status = updates[0]["status"][1];
      }
      return status;
    },
    getAwardCenterColor(center) {
      if (center === "GovEx") {
        return "var(--govex)";
      } else if (center === "BCPI") {
        return "var(--bcpi)";
      } else {
        return ""; // Set the default color here
      }
    },
    getTableCellClasses(award) {
      return {
        "table-item": true,
        "table-link": award && award.length > 0,
      };
    },
    mapCenterFromQueryString(queryCenter) {
      if (!queryCenter) {
        return "";
      }
      const centerMap = {
        govex: "GovEx",
        bcpi: "BCPI",
        partner: "Partner",
      };
      return centerMap[queryCenter.toLowerCase()] || "";
    },
  },
};
</script>
